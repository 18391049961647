import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import menuNames from "../menuNames.json";

class SeccionClientes extends Component {

  renderClientes = (objeto, nombre, pagina) => {
    let clientes = [];
    let i = 0;
    for (let index in objeto) {
      clientes[i] = (
        <div key={index}>
          <a href={objeto[index][pagina]} target="blank">
          <OverlayTrigger key={index} placement="top" overlay={<Tooltip id="tooltip">{objeto[index][nombre]}</Tooltip>}>
            <img
              src={`./img/clientes/${index}.jpg`}
              alt={objeto[index][nombre]}
            />
            </OverlayTrigger>
          </a>
        </div>
      );
      i++;
    }

    return clientes;
  };

  render() {
    return (
      <div>
        <h1 className="seccion">{menuNames.menu3.submenu3a}</h1>
        <div className="clientes">
          {this.renderClientes(menuNames["3a"], "nombre", "pagina")}
        </div>
      </div>
    );
  }
}

export default SeccionClientes;
